import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, filter, of, switchMap } from 'rxjs';
import { RolesRightsService } from './services/roles-rights.service';
import { UserService } from './services/user.service';

@Injectable({
  providedIn: 'root',
})
export class RightsGuard  {
  constructor(
    private rolesRightsService: RolesRightsService,
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.userService.authState$.pipe(
      filter((authState) => authState !== null),
      switchMap((authState) => {
        if (
          authState &&
          this.rolesRightsService.hasAccess(
            state.url,
            this.userService.userData
          )
        ) {
          return of(true);
        } else {
          this.router.navigate(['/home']);
          return of(false);
        }
      })
    );
  }
}
