import { Timestamp } from '@firebase/firestore';
import { CreatedBy } from './general.model';

export interface Transaction {
  id?: string;
  policyId?: string;
  policyNumber?: string;
  method?: PaymentMethod | '';
  source?: PaymentSource;
  sourceInfo?: string;
  payments?: Payment[];
  amount?: number;
  transactionDate?: Timestamp;
  receiptNumber?: string;
  receiptReferenceNumber?: string;
  counter?: string;
  status?: string;
  statusInfo?: string;
  isOpen?: boolean;
  isUnauthorized?: boolean;
  isReversed?: boolean;
  isVoid?: boolean;
  voidInfo?: string;
  createdBy?: CreatedBy;
  createdOn?: Timestamp;
}

export interface Payment {
  items?: ProductItem[];
  periodPaid?: Timestamp;
}

export interface ProductItem {
  id?: string;
  product?: string;
  amount?: number;
}

export enum TransactionStatus {
  ALL = 'ALL',
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  UNIDENTIFIED = 'UNIDENTIFIED',
  IDENTIFIED = 'IDENTIFIED',
  REVERSED = 'REVERSED',
  TRANSFERRED_IN = 'TRANSFERRED IN',
  TRANSFERRED_OUT = 'TRANSFERRED OUT',
  UNALLOCATED = 'UNALLOCATED',
  ALLOCATED = 'ALLOCATED',
}

export enum PaymentMethod {
  ALL = 'ALL',
  ONLINE_CASH = 'ONLINE CASH',
  ONLINE_DEBIT_CARD = 'ONLINE DEBIT CARD',
  ONLINE_CREDIT_CARD = 'ONLINE CREDIT CARD',
  ONLINE_CARD = 'ONLINE CARD',
  ONLINE_EFT = 'ONLINE EFT',
  ONLINE_DEBIT_ORDER = 'ONLINE DEBIT ORDER',
  OFFLINE_CASH = 'OFFLINE CASH',
  OFFLINE_DEBIT_CARD = 'OFFLINE DEBIT CARD',
  OFFLINE_CREDIT_CARD = 'OFFLINE CREDIT CARD',
  OFFLINE_CARD = 'OFFLINE CARD',
  OFFLINE_EFT = 'OFFLINE EFT',
  SYSTEM_ALLOCATION = 'SYSTEM ALLOCATION',
}

export enum PaymentSource {
  ALL = 'ALL',
  PAY_AT = 'PAY@',
  NETCASH = 'NETCASH',
  DASHPAY = 'DASHPAY',
  SURESWIPE = 'SURESWIPE',
  SYSTEM = 'SYSTEM',
}

export interface TransactionCount {
  id?: string;
  count?: {
    [key in TransactionStatus]?: number;
  };
}

export interface NetcashBatchHistory {
  id?: string;
  batches?: NetcashBatch[];
}

export interface NetcashBatch {
  id?: string;
  name?: string;
  actionDay: number;
  service: string;
  volume: number;
  total: number;
  status?: string;
  errors?: NetcashError[];
  actionDate?: Timestamp;
  createdBy?: CreatedBy;
  createdOn?: Timestamp;
}

export enum NetcashBatchStatus {
  ALL = 'ALL', // "ALL" is not present in the original model
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PARTIAL = 'PARTIAL',
}

export interface NetcashError {
  accountRef?: string;
  line?: number;
  message?: string;
  amount?: number;
}

export enum NetcashTransactionType {
  OpeningClosingBalance = 0,
  TwoDayDebitOrder = 1,
  TwoDayCreditCardDebitOrder = 2,
  DebitOrderUnpaid = 4,
  SameDayDebitOrder = 5,
  SameDayCreditCardDebitOrder = 6,
  DebiCheckSuccessful = 7,
  DebiCheckUnsuccessful = 8,
  DebiCheckTracking = 9,
  DebiCheckDisputed = 10,
  SameDayCreditorPayment = 11,
  CreditorPaymentReturn = 12,
  DepositReceived = 13,
  RiskReports = 14,
  SameDaySalaryPayment = 15,
  ServiceFee = 18,
  BankTransferToClient = 19,
  InterestPaidToNetcash = 20,
  NetcashInterAccountTransfer = 21,
  AVS = 22,
  SalaryPaymentReturn = 23,
  DatedSalaryPayment = 24,
  DatedCreditorPayment = 25,
  InterSystemTransfer = 26,
  BankTransferReturn = 27,
  AccountBalanceRecovery = 28,
  CashnetCashPayment = 29,
  AccountBalanceRecoveryReturn = 30,
  InterestPaidToMerchant = 31,
  OutputVAT = 32,
  CreditCardPayment = 33,
  CreditCardDeclined = 34,
  CreditCardDispute = 35,
  CreditCardRefund = 36,
  DepositReturn = 42,
  RetailPayment = 43,
  ScanToPayPayment = 44,
  EFTPayment = 45,
  RetailPaymentReturn = 46,
  ScanToPayDeclined = 47,
  EFTPaymentReturn = 48,
  InterestPaidToNetcashReversal = 50,
  InterestPaidToMerchantsReversal = 51,
  CardAuthorisation = 52,
  Rebate = 53,
  OzowAuth = 54,
  OzowSuccess = 55,
  OzowFailure = 56,
  DebitOrderCreditCardUnpaid = 57,
  DebicheckMandateRequest = 58,
  BankAccountRedirect = 61,
  AccountVerificationBulk = 62,
  VisaClickToPayPayment = 63,
  VisaClickToPayDecline = 64,
  VisaClickToPayRefund = 65,
  VisaClickToPayDispute = 66,
  OzowRecall = 67,
  MasterPassQR = 68,
  ElectronicMandate = 69,
  ClientDeposit = 70,
  TwoDayInvalidDebitOrder = 74,
  SameDayInvalidDebitOrder = 75,
  RejectedDebitOrder = 76,
  CashnetControl = 77,
  ScanToPayRefund = 78,
  DebicheckMandate = 85,
  DebiCheckPreSubmission = 86,
  DebiCheckInvalidPreSubmission = 87,
  DebiCheckRejectedSubmission = 88,
  FundTransfer = 89,
  MobicredAuth = 90,
  MobicredSuccess = 91,
  MobicredDecline = 92,
  MobicredRefund = 93,
  RetailCapitalTransfer = 94,
  RetailCapitalTransferFailed = 95,
  DebitOrderCardRefund = 96,
  Adjustment = 97,
  RealTimeCreditorPayment = 98,
  RealTimeSalaryPayment = 99,
  RealTimeTransferToClient = 100,
  PayflexAuth = 101,
  PayflexSuccess = 102,
  PayflexDecline = 103,
  PayflexRefund = 104,
}

export enum AdditionReasons {
  TRANSACTION_MISSING = 'TRANSACTION MISSING',
  REFUND = 'REFUND',
  RECONCILIATION = 'RECONCILIATION',
}

export enum ReversalReasons {
  WRONG_AMOUNT = 'WRONG AMOUNT',
  WRONG_MEMBERSHIP = 'WRONG MEMBERSHIP',
  TRANSACTION_CANCELLED = 'TRANSACTION CANCELLED',
  CASH_INSTEAD_CARD = 'CASH INSTEAD CARD',
}
