import { EventEmitter, Injectable } from '@angular/core';
import {
  Observable,
  Subject,
  catchError,
  map,
  takeUntil,
  throwError,
} from 'rxjs';
import { Log, Event } from '../models/log.model';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from './snack-bar.service';
import { DateTimeService } from './date-time.service';
import {
  Payment,
  Transaction,
  TransactionStatus,
} from '../models/transaction.model';
import { Timestamp } from '@firebase/firestore';
import { uuidv4 } from '@firebase/util';
import { UserService } from './user.service';
import { Policy } from '../models/policy.model';
import {
  doc,
  getFirestore,
  updateDoc,
  arrayUnion,
  DocumentReference,
  collection,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class TransactionLogService {
  private dbModular = getFirestore();

  // Form state
  loading = false;
  success = false;
  claim = false;

  CRUDtype: string;
  transactionFilter: string = 'ALL';
  searchText: string = '';

  selectedTransactionLog: Log | undefined;
  allTransactionLogs: Event[] | undefined;
  filteredTransactionLogs: Event[] = [];

  policyLogDoc: DocumentReference<Log>;
  selectedTransactionLog$: Observable<Log | undefined>;
  destroy$ = new Subject<void>();

  selectedTransactionLogUpdated = new EventEmitter<void>();

  dataSourceTransactionLogs: MatTableDataSource<any>;

  constructor(
    private snackBarService: SnackBarService,
    private dateTimeService: DateTimeService,
    private userService: UserService
  ) {
    this.userService.destroy$.pipe().subscribe(() => this.cleanUp());
  }

  /**
   * Sets the selected transaction log based on a given policy ID with real-time subscription.
   * @param policyId - The ID of the policy to fetch the transaction log for.
   * @returns A promise that resolves when the selected transaction log is set.
   */
  async setSelectedTransactionLog(policyId: string): Promise<void> {
    const transactionLogRef = collection(this.dbModular, 'transactionLog');
    const q = query(transactionLogRef, where('policyId', '==', policyId));

    return new Promise<void>((resolve, reject) => {
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          if (!querySnapshot.empty) {
            const docSnapshot = querySnapshot.docs[0];
            this.selectedTransactionLog = {
              ...(docSnapshot.data() as Log),
              id: docSnapshot.id,
            };
            this.refreshAllTransactionLogs();
            this.selectedTransactionLogUpdated.next();
            resolve();
          } else {
            this.snackBarService.openRedSnackBar('TRANSACTION LOG NOT FOUND!');
            reject(new Error('TRANSACTION LOG NOT FOUND!'));
          }
        },
        (error) => {
          if (error instanceof Error) {
            this.snackBarService.latestError = error.message;
            this.snackBarService.openRedSnackBar(
              'ERROR FETCHING TRANSACTION LOGS'
            );
            reject(error);
          }
        }
      );

      this.destroy$.subscribe(() => unsubscribe());
    });
  }

  /**
   * Asynchronously creates a transaction log based on provided parameters.
   * If a valid transaction is provided, it generates the relevant log details and updates log events accordingly.
   * @param transaction The transaction details for which the log is to be created.
   * @param newStatus The new status of the transaction.
   * @param reason The reason for any changes or actions performed on the transaction.
   * @param newPolicy The new policy associated with the transaction (if applicable).
   */
  async createTransactionLog(
    transaction: Transaction,
    newStatus: string = '',
    reason: string = '',
    newPolicy?: Policy,
    newReceiptNumber?: string
  ) {
    this.loading = true;
    try {
      if (this.isValidTransaction(transaction)) {
        const logDetails = await this.generateLogDetails(
          transaction,
          newStatus,
          reason,
          newPolicy,
          newReceiptNumber
        );
        if (
          transaction.policyId &&
          transaction.status !== TransactionStatus.UNIDENTIFIED &&
          transaction.status !== TransactionStatus.IDENTIFIED
        )
          await this.updateLogEvents(
            logDetails.event,
            logDetails.type,
            transaction.receiptNumber?.toString() || '',
            transaction.policyId || ''
          );
      }
      this.success = true;
    } catch (err) {
      this.handleLoggingError(err);
    } finally {
      this.loading = false;
    }
  }

  /**
   * Checks if the provided transaction is valid based on certain properties.
   * @param transaction The transaction object to validate.
   * @returns A boolean value indicating whether the transaction is valid.
   */
  private isValidTransaction(transaction: Transaction): boolean {
    return (
      !!transaction.payments &&
      !!transaction.status &&
      !!transaction.receiptNumber
    );
  }

  /**
   * Asynchronously generates the log details based on transaction properties and status.
   * Determines the relevant logging action based on the transaction's new status.
   * @param transaction The transaction details.
   * @param newStatus The new status of the transaction.
   * @param reason The reason for any changes or actions performed on the transaction.
   * @param newPolicy The new policy associated with the transaction (if applicable).
   * @returns An object with the event description and type of log.
   */
  private async generateLogDetails(
    transaction: Transaction,
    newStatus: string,
    reason: string,
    newPolicy?: Policy,
    newReceiptNumber?: string
  ): Promise<{ event: string; type: string }> {
    const amount = this.currencyToString(transaction.amount);

    if (
      newStatus.includes('TRANSFERRED') &&
      newPolicy?.id &&
      newReceiptNumber
    ) {
      return await this.handleTransfer(
        transaction,
        newPolicy,
        newReceiptNumber,
        amount
      );
    } else if (newStatus === TransactionStatus.REVERSED) {
      return this.handleReversal(transaction, reason, amount);
    }
    return this.handleGeneralStatus(transaction, amount, reason);
  }

  /**
   * Handles the logging of transfer-related events and updates relevant policies.
   * @param transaction The transaction that was transferred.
   * @param newPolicy The policy to which the transaction was transferred.
   * @param amount The amount involved in the transfer.
   * @returns An object with the event description and type of log.
   */
  private async handleTransfer(
    transaction: Transaction,
    newPolicy: Policy,
    newReceiptNumber: string,
    amount: string
  ): Promise<{ event: string; type: string }> {
    let extraInfo = '';

    if (
      transaction.status === TransactionStatus.UNALLOCATED ||
      transaction.status === 'UNALLOCATED FUNDS AMENDMENT'
    ) {
      extraInfo = ` The ${amount} was removed from unallocated funds.`;
    } else if (transaction.status === TransactionStatus.PAID) {
      extraInfo = ` ${amount} payment for premiums/products was reversed.`;
    }

    const event = `${amount} transferred from Policy ${transaction.policyNumber} to Policy ${newPolicy.policyNumber} as receipt ${newReceiptNumber}.${extraInfo}`;
    const type = 'TRANSFERRED OUT';
    const from =
      transaction.status !== TransactionStatus.UNIDENTIFIED
        ? `Policy ${transaction.policyNumber}`
        : `being ${TransactionStatus.UNIDENTIFIED}`;

    await this.updateLogEvents(
      `Receipt ${transaction.receiptNumber} transferred from ${from} to Policy ${newPolicy.policyNumber}. ${amount} added to unallocated funds.`,
      'TRANSFERRED IN',
      newReceiptNumber?.toString() || '',
      newPolicy?.id || ''
    );

    return { event, type };
  }

  /**
   * Handles the logging of transaction reversals.
   * @param transaction The transaction that was reversed.
   * @param reason The reason for the reversal.
   * @param amount The amount involved in the reversal.
   * @returns An object with the event description and type of log.
   */
  private handleReversal(
    transaction: Transaction,
    reason: string,
    amount: string
  ): { event: string; type: string } {
    let event = '';
    let type = '';

    switch (transaction.status) {
      case TransactionStatus.UNALLOCATED:
        event = `Mismatched transaction of ${amount} was reversed from unallocated funds. Reason: ${reason}`;
        break;
      case TransactionStatus.ALLOCATED:
        event = `Allocated transaction of ${amount} was reversed to unallocated funds. Reason: ${reason}`;
        break;
      case 'TRANSFERRED FROM':
        event = `Transferred in transaction of ${amount} was reversed from unallocated funds. Reason: ${reason}`;
        break;
      case TransactionStatus.PAID:
        event = `${amount} payment for premiums was reversed. Reason: ${reason}`;
        break;
      case 'UNALLOCATED FUNDS AMENDMENT':
        event = `${amount} was manually reversed from unallocated funds. Reason: ${reason}`;
        type = 'ALLOCATION';
        break;
    }

    if (type === '') type = 'REVERSAL';
    return { event, type };
  }

  /**
   * Handles general transaction logging based on various transaction statuses.
   * @param transaction - The transaction to be logged.
   * @param amount - The amount involved in the transaction.
   * @param reason - The reason for any changes or actions performed on the transaction.
   * @returns An object with the event description and type of log.
   */
  private handleGeneralStatus(
    transaction: Transaction,
    amount: string,
    reason = ''
  ): { event: string; type: string } {
    let event = '';
    let type = 'NEW TRANSACTION';

    if (transaction.status === TransactionStatus.PAID) {
      event = `Paid ${amount} via ${
        transaction.method
      } for ${this.displayPeriodPaid(
        this.getTransactionPeriodsPaid(transaction.payments || [])
      )}.`;
    } else if (transaction.status === TransactionStatus.UNALLOCATED) {
      event = `Received mismatched amount of ${amount} via ${transaction.method}. Amount added to unallocated funds as no premiums were paid.`;
      type = 'FUNDS RECEIVED';
    } else if (transaction.status === TransactionStatus.ALLOCATED) {
      event = `Allocated ${amount} from unallocated funds for ${this.displayPeriodPaid(
        this.getTransactionPeriodsPaid(transaction.payments || [])
      )}.`;
      type = 'ALLOCATION';
    } else if (transaction.status === 'UNALLOCATED FUNDS AMENDMENT') {
      event = `${amount} was manually added to unallocated funds. Reason: ${reason}`;
      type = 'ALLOCATION';
    }

    return { event, type };
  }

  /**
   * Logs any errors encountered during the logging process.
   * @param err The error encountered.
   */
  private handleLoggingError(err: any) {
    if (err instanceof Error) {
      this.snackBarService.latestError = err.message;
    }
    this.snackBarService.openRedSnackBar('ERROR LOGGING TRANSACTION');
  }

  /**
   * Updates the transaction logs with new events.
   * @param log The log or description of the event.
   * @param type The type of event.
   * @param referenceNumber The reference number for the transaction.
   * @param changeDocId The document ID where the changes occurred.
   */
  private async updateLogEvents(
    log: string,
    type: string,
    referenceNumber: string,
    changeDocId: string
  ) {
    this.loading = true;

    if (
      !this.selectedTransactionLog?.policyId ||
      changeDocId !== this.selectedTransactionLog?.policyId
    )
      await this.setSelectedTransactionLog(changeDocId);

    const transactionLogDoc = this.selectedTransactionLog;

    try {
      if (!transactionLogDoc?.id) {
        throw new Error('Transaction Log Document ID undefined');
      }

      const id = uuidv4();
      const createdBy = {
        uid: this.userService.userData?.uid,
        displayName: this.userService.userData?.displayName,
        email: this.userService.userData?.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };
      const createdOn = Timestamp.now();
      const newEvent = {
        id,
        event: log,
        type,
        referenceNumber,
        createdOn,
        createdBy,
      };

      const transactionLogRef = doc(
        this.dbModular,
        'transactionLog',
        transactionLogDoc?.id
      );
      await updateDoc(transactionLogRef, {
        events: arrayUnion(newEvent),
      });

      this.success = true;
    } catch (err) {
      this.handleLoggingError(err);
    }
    this.loading = false;
  }

  applyFilter(filterValue?: string): void {
    if (filterValue) {
      this.searchText = filterValue.trim().toUpperCase();
    }

    this.filteredTransactionLogs =
      this.allTransactionLogs?.filter((item) =>
        this.doesLogMatchFilter(item, this.searchText)
      ) ?? [];
  }

  public refreshAllTransactionLogs() {
    if (this.selectedTransactionLog?.events) {
      const sortedLogs = this.selectedTransactionLog.events
        .slice()
        .sort((a, b) => {
          const aCreatedOn = a.createdOn ? a.createdOn.toDate().getTime() : 0;
          const bCreatedOn = b.createdOn ? b.createdOn.toDate().getTime() : 0;
          return bCreatedOn - aCreatedOn;
        });
      this.allTransactionLogs = sortedLogs;
      this.applyFilter();
    }
  }

  private doesLogMatchFilter(item: any, filterValue: string): boolean {
    const typeMatches =
      this.transactionFilter === 'ALL' ||
      item.type.includes(this.transactionFilter);

    const event = item.event ? item.event.toUpperCase() : '';
    const strippedEvent = item.event
      ? this.stripSpacesAndCommas(item.event).toUpperCase()
      : '';
    const referenceNumber = item.referenceNumber
      ? item.referenceNumber.toUpperCase()
      : '';
    const createdBy = item.createdBy?.displayName
      ? item.createdBy.displayName.toUpperCase()
      : '';

    const upperCaseFilterValue = filterValue.toUpperCase();

    const textMatches =
      event.includes(upperCaseFilterValue) ||
      strippedEvent.includes(upperCaseFilterValue) ||
      referenceNumber.includes(upperCaseFilterValue) ||
      createdBy.includes(upperCaseFilterValue);

    return typeMatches && textMatches;
  }

  private stripSpacesAndCommas(value: string): string {
    return value.replace(/[\s,]/g, '');
  }

  updateCurrentTransactionLogs(events: Event[]) {
    if (!this.dataSourceTransactionLogs) {
      this.dataSourceTransactionLogs = new MatTableDataSource<Event>(events);
    } else {
      this.dataSourceTransactionLogs.data = events;
      this.dataSourceTransactionLogs._updateChangeSubscription();
    }
  }

  /**
   * Retrieves the periods (timestamps) for which payments were made.
   * @param payments - Array of payments to extract periods from.
   * @returns An array of timestamps representing the periods of the payments.
   */
  getTransactionPeriodsPaid(payments: Payment[]): Array<Timestamp> {
    const periodsPaid: Array<Timestamp> = [];

    if (payments && Array.isArray(payments)) {
      for (const payment of payments) {
        if (payment.periodPaid) {
          periodsPaid.push(payment.periodPaid);
        }
      }
    }

    return periodsPaid;
  }

  /**
   * Formats a payment method string, and adjusts the method based on the status if provided.
   *
   * @param {string | undefined} method - The payment method to format.
   * @param {string} [status] - The associated status for additional formatting.
   * @returns {string} Formatted payment method.
   */
  formatMethod(method: string | undefined) {
    if (method) {
      return method.replace(/^(OFFLINE |ONLINE )/, '');
    } else {
      return '-';
    }
  }

  /**
   * Displays a string representation of paid periods based on Timestamps.
   * If no periods have been paid, it returns 'NO PERIODS PAID'.
   *
   * @param {Timestamp[]} periodPaid - Array of Timestamps representing periods.
   * @returns {string} Formatted string of periods paid.
   */
  displayPeriodPaid(periodPaid: Timestamp[]): string {
    if (!periodPaid || periodPaid.length === 0) {
      return 'NO PERIODS PAID';
    }

    const sortedPeriods = periodPaid
      .map((ts) => this.dateTimeService.timestampToDate(ts))
      .filter((date): date is Date => date !== null)
      .sort((a, b) => a.getTime() - b.getTime());

    if (sortedPeriods.length === 0) {
      return 'NO PERIODS PAID';
    }

    const firstPeriod = this.formatPeriod(sortedPeriods[0]);
    const lastPeriod = this.formatPeriod(
      sortedPeriods[sortedPeriods.length - 1]
    );

    if (sortedPeriods.length === 1 || firstPeriod === lastPeriod) {
      return firstPeriod;
    } else {
      return `${firstPeriod} - ${lastPeriod}`;
    }
  }

  /**
   * Formats the provided date or timestamp into a string with month and year.
   * For example, 'January 2022'.
   *
   * @param {Date | Timestamp | undefined} date - The date or timestamp to format.
   * @returns {string} The formatted period string.
   */
  formatPeriod(date: Date | Timestamp | undefined): string {
    if (date) {
      if (date instanceof Timestamp) date = date.toDate();

      if (date instanceof Date) {
        return (
          date
            .toLocaleString('default', { month: 'long' })
            .toLocaleUpperCase() +
          ' ' +
          date.getFullYear()
        );
      }
    }
    return ``;
  }

  /**
   * Converts a numeric amount to a string representation in ZAR currency format.
   * If the amount is not provided or is zero, it defaults to 'R0.00'.
   *
   * @param {number | undefined} amount - The numeric value to convert.
   * @returns {string} The amount in ZAR currency format.
   */
  currencyToString(amount: number | undefined) {
    if (!amount) amount = 0;
    return amount.toLocaleString('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 2,
    });
  }

  /**
   * Cleans up and resets the service properties and subscriptions.
   */
  cleanUp() {
    this.destroy$.next();
  }
}
