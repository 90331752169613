import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialog,
} from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import {
  BankAccountTypes,
  DebitOrder,
  DebitOrderDeductionsDays,
  LastPeriodPaidAmendmentReasons,
  Member,
  MemberStatus,
  Policy,
  PolicyStatus,
  ProductPaymentStatus,
  bankBranchMap,
  branchCodeBankMap,
} from 'src/app/models/policy.model';
import {
  LogSearch,
  PolicyHit,
  MemberPolicySearch,
} from 'src/app/models/search.model';
import { AddOnService } from 'src/app/services/add-on.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { FileService } from 'src/app/services/file.service';
import { FilterService } from 'src/app/services/filter.service';
import { ImportService } from 'src/app/services/import.service';
import { PolicyLogService } from 'src/app/services/policy-log.service';
import { MessageService } from 'src/app/services/message.service';
import { PlanService } from 'src/app/services/plan.service';
import { PolicyService } from 'src/app/services/policy.service';
import { RolesRightsService } from 'src/app/services/roles-rights.service';
import { SearchService } from 'src/app/services/search.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { UserService } from 'src/app/services/user.service';
import { MainService } from 'src/app/services/main.service';
import 'firebase/functions';
import { Timestamp } from '@firebase/firestore';
import { MatDatepicker } from '@angular/material/datepicker';
import { ValidationService } from 'src/app/services/validation.service';
import { ReportService } from 'src/app/services/report.service';
import {
  AdditionReasons,
  PaymentMethod,
  ReversalReasons,
  TransactionStatus,
} from 'src/app/models/transaction.model';
import { uuidv4 } from '@firebase/util';
import { UserLocation } from 'src/app/models/general.model';
import { CashUp } from 'src/app/models/user.model';
import { ImportType } from 'src/app/models/import.model';
import { BackupService } from 'src/app/services/backup.service';
import { Collections } from 'src/app/models/backup.model';
import { AddOnStatus } from 'src/app/models/addOn.model';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  displayedDuplicateMemberId: string[] = [
    'policy',
    'memberType',
    'idNumber',
    'firstName',
    'lastName',
    'status',
    'button',
  ];

  displayedSearchPolicyColumns: string[] = [
    'policy',
    'idNumber',
    'firstName',
    'lastName',
    'plan',
    'status',
  ];

  displayedSelectedTransactionColumns: string[] = [
    'receiptNumber',
    'date',
    'amount',
    'method',
    'status',
  ];

  referenceNum: string = '';

  //Variables {
  dialogTitle: string;
  dialogType: string;
  collection: string;
  inputText: string | undefined;
  searchText: string;
  periodsPaid: string;
  banks: string[];
  branchCodes: string[];
  isDuplicateReportName: boolean = false;

  selectedItem: any;
  formValues: any;
  selectedPolicy: any;
  offlineReceiptStatus: any;
  index: number;
  maxChars = 300;
  remainingChars = this.maxChars;

  allowedDebitOrderDeductionDays = DebitOrderDeductionsDays;
  bankAccountTypes = BankAccountTypes;

  additionReasons = Object.values(AdditionReasons);
  reversalReasons = Object.values(ReversalReasons);
  lastPeriodPaidAmendmentReasons = Object.values(
    LastPeriodPaidAmendmentReasons
  );
  collections = Object.values(Collections);
  ImportType = ImportType;
  PaymentMethod = PaymentMethod;

  primaryMember: Member | undefined;

  hits: LogSearch[] = [];

  policyHits: MemberPolicySearch[] = [];

  productPaymentStatusToOverride: ProductPaymentStatus | undefined;

  dataSourceSelectedTransaction: MatTableDataSource<any>;
  @ViewChild('receiptReferenceNumberInput')
  receiptReferenceNumberInput: ElementRef;
  @ViewChild('customName') customNameInput: MatInput;
  @ViewChild('chooseFileButton', { static: false })
  chooseFileButton: ElementRef;

  commentForm: FormGroup;
  roleNameForm: FormGroup;
  manualReceiptForm: FormGroup;
  waitingPeriodForm: FormGroup;
  reasonForm: FormGroup;
  offLineReceiptForm: FormGroup;
  amendUnallocatedFundsForm: FormGroup;
  lastPeriodPaidForm: FormGroup;
  scheduledPremiumForm: FormGroup;
  intendedPaymentDayForm: FormGroup;
  debitOrderForm: FormGroup;
  newLocationForm: FormGroup;
  cashUpForm: FormGroup;
  importForm: FormGroup;
  backupAndRestoreForm: FormGroup;

  minDate = new Date();
  maxDate = new Date();
  today = new Date();
  periods: any[];

  // Mapping of banks to their branch codes

  noOptionsAvailable = false;
  addFunds = false;

  //Variables }

  constructor(
    public mainService: MainService,
    public dialogRef: MatDialogRef<DialogComponent>,
    public planService: PlanService,
    public addOnService: AddOnService,
    public policyService: PolicyService,
    public fileService: FileService,
    private snackBarService: SnackBarService,
    public dateTmeService: DateTimeService,
    private messageService: MessageService,
    private filterService: FilterService,
    public userService: UserService,
    public rolesRightsService: RolesRightsService,
    public searchService: SearchService,
    public dateTimeService: DateTimeService,
    public policyLogService: PolicyLogService,
    public importService: ImportService,
    public validationService: ValidationService,
    public reportService: ReportService,
    public transactionService: TransactionService,
    public backupService: BackupService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,

    @Inject(MAT_DIALOG_DATA) data: DialogComponent
  ) {
    //Data Passed from the component {
    this.selectedItem = data.selectedItem;
    this.dialogType = data.dialogType;
    this.dialogTitle = data.dialogTitle;
    this.formValues = data.formValues;
    this.index = data.index;
    this.collection = data.collection;
    this.inputText = data.inputText;
    //Data Passed from the component }

    this.userService.destroy$
      .pipe(takeUntil(this.userService.ngUnsubscribe$))
      .subscribe(() => this.closeDialog());
  }

  async ngOnInit() {
    if (this.dialogType === 'commentDialog') {
      this.commentForm = this.fb.group({
        comment: ['', [Validators.required]],
      });

      if (this.selectedItem) this.commentForm.patchValue(this.selectedItem);
    } else if (
      this.dialogType === 'manualReceiptDialog' ||
      this.dialogType === 'unallocatedFundsDialog'
    ) {
      this.manualReceiptForm = this.fb.group({
        amount: ['', [Validators.required]],
        method: ['', [Validators.required]],
      });

      this.periods = this.transactionService.getPolicyPremiumPeriods(12);

      this.policyService.selectedPolicyChanged.subscribe(() => {
        this.periods = this.transactionService.getPolicyPremiumPeriods(12);
      });

      this.noOptionsAvailable = this.periods.every(
        (period) =>
          period.premium >
          (this.policyService.selectedPolicy?.unallocatedBalance ?? 0)
      );
    } else if (this.dialogType === 'transferTransactionDialog') {
      if (!this.planService.allPlans || this.planService.plansUnsubscribed) {
        await this.planService.loadPlans();
      }
      this.dataSourceSelectedTransaction = new MatTableDataSource([
        this.selectedItem,
      ]);
    } else if (this.dialogType.includes('WaitingPeriodDialog')) {
      this.waitingPeriodForm = this.fb.group({
        requestedWaitingDate: ['', [Validators.required]],
      });
      this.waitingPeriodForm
        .get('requestedWaitingDate')
        ?.setValue(
          this.dateTimeService.timestampToDate(this.selectedItem.waitingDate)
        );
    } else if (this.dialogType.includes('reverseTransactionDialog')) {
      this.reasonForm = this.fb.group({
        reason: ['', [Validators.required]],
      });
    } else if (
      this.dialogType === 'editUnallocatedFundsDialog' ||
      this.dialogType === 'transferUnallocatedFundsDialog'
    ) {
      this.amendUnallocatedFundsForm = this.fb.group({
        amount: ['', [Validators.required]],
        additionReason: ['', [Validators.required]],
        reverseReason: ['', [Validators.required]],
      });
    } else if (this.dialogType === 'productPaymentStatusDialog') {
      this.lastPeriodPaidForm = this.fb.group({
        id: ['', [Validators.required]],
        lastPeriodPaid: ['', [Validators.required]],
        reason: ['', [Validators.required]],
      });

      this.lastPeriodPaidForm.get('id')?.setValue(this.selectedItem.id);

      const lastPeriodPaid = this.dateTimeService.timestampToDate(
        this.selectedItem.lastPeriodPaid
      );

      this.lastPeriodPaidForm.get('lastPeriodPaid')?.setValue(lastPeriodPaid);

      const inceptionDate =
        this.selectedItem.id === this.policyService.selectedPolicy?.planId
          ? this.dateTimeService.timestampToDate(
              this.policyService.selectedPolicy?.inceptionDate
            )
          : this.transactionService.getProductFirstPaymentDate(
              this.policyService.selectedPolicy?.addOns?.find(
                (addOn) => addOn.addOnId === this.selectedItem.id
              )
            );
      if (inceptionDate) {
        this.minDate = inceptionDate;
        this.minDate.setDate(1);
        this.minDate.setMonth(this.minDate.getMonth() - 1);
      }
    } else if (this.dialogType.includes('offlineTransactionDialog')) {
      this.periods = this.transactionService.getPolicyPremiumPeriods(12);
      this.policyService.selectedPolicyChanged.subscribe(() => {
        this.periods = this.transactionService.getPolicyPremiumPeriods(12);
        this.updatePeriodsPaid(this.offLineReceiptForm.get('amount')?.value);
      });

      this.offLineReceiptForm = this.fb.group({
        transactionDate: ['', [Validators.required]],
        receiptReferenceNumber: ['', [Validators.required]],
        amount: ['', [Validators.required]],
        method: ['', [Validators.required]],
      });

      let transactionDateValue;
      if (this.transactionService.offlineReceiptDate) {
        transactionDateValue = new Date(
          this.transactionService.offlineReceiptDate
        );
      } else {
        const today = new Date(); // Get current date and time
        today.setHours(0, 0, 0, 0); // Reset time to start of day
        transactionDateValue = today;
      }
      this.offLineReceiptForm
        .get('transactionDate')
        ?.setValue(transactionDateValue);

      if (
        this.transactionService.offlineReceiptCounter &&
        this.policyService.selectedPolicy
      ) {
        this.offLineReceiptForm
          .get('receiptReferenceNumber')
          ?.setValue(this.transactionService.offlineReceiptCounter);
      }
      this.offLineReceiptForm.get('method')?.setValue('OFFLINE CASH');
      if (this.policyService.selectedPolicy) {
        this.offLineReceiptForm
          .get('amount')
          ?.setValue(this.transactionService.getNextPolicyPremium().amount);
      }

      this.updatePeriodsPaid(this.offLineReceiptForm.get('amount')?.value);
      if (this.policyService.selectedPolicy) {
        this.primaryMember = this.policyService.getPolicyPrimaryMember(
          this.selectedItem
        );
      }
    } else if (this.dialogType === 'scheduledPremiumDialog') {
      this.scheduledPremiumForm = this.fb.group({
        startDate: ['', [Validators.required]],
        standardAmount: ['', [Validators.required]],
        waitingAmount: ['', [Validators.required]],
      });

      if (this.selectedItem) {
        this.scheduledPremiumForm
          .get('startDate')
          ?.setValue(
            this.dateTimeService.timestampToDate(this.selectedItem.startDate)
          );
        this.scheduledPremiumForm
          .get('standardAmount')
          ?.setValue(this.selectedItem.standardAmount);
        this.scheduledPremiumForm
          .get('waitingAmount')
          ?.setValue(this.selectedItem.waitingAmount);
      }

      if (!this.selectedItem) this.index = -1;
    } else if (this.dialogType === 'intendedPaymentDayDialog') {
      const validators = [
        Validators.required,
        Validators.min(1),
        Validators.max(31),
        Validators.pattern('^[0-9]+$'),
      ];

      if (this.policyService.selectedPolicy?.debitOrder?.status === 'ACTIVE') {
        validators.push(
          this.validationService.allowedDaysValidator(DebitOrderDeductionsDays)
        );
      }

      this.intendedPaymentDayForm = this.fb.group({
        intendedPaymentDay: ['', validators],
        reason: ['', [Validators.required]],
      });

      this.intendedPaymentDayForm
        .get('intendedPaymentDay')
        ?.setValue(this.selectedItem.intendedPaymentDay);
    } else if (this.dialogType === 'debitOrderDialog') {
      this.primaryMember = await this.policyService.getPolicyPrimaryMember(
        this.selectedItem
      );
      this.banks = Object.keys(bankBranchMap);
      this.branchCodes = Object.values(bankBranchMap);

      this.initializeDebitOrderForm();
    } else if (this.dialogType === 'newLocationDialog') {
      this.newLocationForm = this.fb.group({
        name: ['', [Validators.required]],
        code: ['', [Validators.required]],
      });

      if (this.selectedItem) {
        this.newLocationForm.get('name')?.setValue(this.selectedItem.name);
        this.newLocationForm.get('code')?.setValue(this.selectedItem.code);
      }
    } else if (this.dialogType === 'cashUpDialog') {
      this.cashUpForm = this.fb.group({
        cashDeclared: ['', [Validators.required]],
        cardDeclared: ['', [Validators.required]],
        comment: ['', [Validators.required]],
      });

      if (this.selectedItem) {
        this.cashUpForm
          .get('cashDeclared')
          ?.setValue(this.selectedItem.cashDeclared);

        this.cashUpForm
          .get('cardDeclared')
          ?.setValue(this.selectedItem.cardDeclared);

        if (this.selectedItem.comment)
          this.cashUpForm.get('comment')?.setValue(this.selectedItem.comment);
      }
    } else if (this.dialogType === 'importDialog') {
      this.fileService.selectedFile = undefined;
      this.importForm = this.fb.group({
        customName: ['', [Validators.required]],
        type: ['', [Validators.required]],
      });
    } else if (
      this.dialogType === 'newBackupDialog' ||
      this.dialogType === 'restoreBackupDialog'
    ) {
      this.backupAndRestoreForm = this.fb.group({
        collections: ['', [Validators.required]],
      });
    }
  }

  ngAfterViewInit() {
    if (this.dialogTitle === 'EDIT FILE TITLE') {
      this.customNameInput.value = this.inputText;
    } else if (this.dialogTitle === 'UPLOAD') {
      this.chooseFileButton.nativeElement.click();
    }

    if (this.dialogType.includes('offlineTransactionDialog'))
      setTimeout(() => {
        this.receiptReferenceNumberInput.nativeElement.focus();
      }, 500);
  }

  //Close Dialog Do Nothing {
  closeDialog(): void {
    this.dialogRef.close();
  }
  //Close Dialog Do Nothing }

  initializeDebitOrderForm() {
    this.debitOrderForm = this.fb.group({
      bank: ['', Validators.required],
      branchCode: ['', Validators.required],
      accountNumber: ['', Validators.required],
      accountType: ['', Validators.required],
      accountName: ['', Validators.required],
      accountHolderId: ['', Validators.required],
      deductionDay: ['', Validators.required],
      changeImmediately: ['', Validators.required],
      status: ['', Validators.required],
      referenceNumber: ['', Validators.required],
    });

    // Set initial values and subscriptions for the form
    this.setInitialFormValues();
    this.setupFormSubscriptions();
  }

  setInitialFormValues() {
    // Set initial values for the form controls
    this.debitOrderForm
      .get('accountNumber')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.accountNumber
          : ''
      );

    this.debitOrderForm
      .get('accountType')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder?.accountType
          ? this.policyService.selectedPolicy.debitOrder.accountType
          : 1
      );

    this.debitOrderForm
      .get('accountName')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.accountName
          : this.primaryMember?.firstName
          ? `${this.primaryMember.firstName[0]} ${this.primaryMember.firstName}`
          : ''
      );

    this.debitOrderForm
      .get('accountHolderId')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.accountHolderId
          : this.primaryMember
          ? this.primaryMember.idNumber
          : ''
      );

    this.debitOrderForm
      .get('deductionDay')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.deductionDay
          : 1
      );

    this.debitOrderForm
      .get('changeImmediately')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.changeImmediately
          : false
      );

    this.debitOrderForm
      .get('status')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.status
          : 'ACTIVE'
      );

    this.debitOrderForm
      .get('referenceNumber')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.referenceNumber
          : ''
      );

    // For the bank, set the value based on the selectedPolicy.debitOrder if it exists
    this.debitOrderForm
      .get('bank')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.bank
          : ''
      );

    // For the branchCode, set the value based on the selectedPolicy.debitOrder if it exists
    this.debitOrderForm
      .get('branchCode')
      ?.setValue(
        this.policyService.selectedPolicy?.debitOrder
          ? this.policyService.selectedPolicy.debitOrder.branchCode
          : ''
      );
  }

  setupFormSubscriptions() {
    // Subscription for bank value changes
    this.debitOrderForm
      .get('bank')!
      .valueChanges.subscribe((bank: keyof typeof bankBranchMap) => {
        const branchCode =
          this.policyService.selectedPolicy?.debitOrder &&
          this.policyService.selectedPolicy.debitOrder.bank === bank
            ? this.policyService.selectedPolicy.debitOrder.branchCode
            : bankBranchMap[bank];

        if (branchCode) {
          this.debitOrderForm
            .get('branchCode')!
            .setValue(branchCode, { emitEvent: false });
        }
        this.setAccountType();
      });

    // Subscription for account number changes
    this.debitOrderForm.get('accountNumber')!.valueChanges.subscribe(() => {
      this.setAccountType();
    });

    // Subscription for branch code changes
    this.debitOrderForm
      .get('branchCode')!
      .valueChanges.subscribe((branchCode) => {
        let banksForBranchCode = branchCodeBankMap[branchCode];
        let selectedBank = null;

        if (this.policyService.selectedPolicy?.debitOrder) {
          const debitOrderBank =
            this.policyService.selectedPolicy.debitOrder.bank;
          if (
            debitOrderBank !== undefined &&
            banksForBranchCode.includes(debitOrderBank)
          ) {
            selectedBank = debitOrderBank;
          }
        }

        if (
          !selectedBank &&
          banksForBranchCode &&
          banksForBranchCode.length > 0
        ) {
          // Logic to determine which bank to select if there are multiple options
          selectedBank = banksForBranchCode[0]; // This is a simple default. Adjust as needed.
        }

        if (selectedBank) {
          this.debitOrderForm
            .get('bank')!
            .setValue(selectedBank, { emitEvent: false });
        } else {
          console.log('No Bank found for Branch Code:', branchCode);
          // Handle the case where no bank is determined
        }
        this.setAccountType();
      });
  }

  setAccountType() {
    const bank = String(this.debitOrderForm.get('bank')?.value);
    const accountNumber = this.debitOrderForm.get('accountNumber')?.value;

    let accountType = '1'; // Default to CHEQUE/CURRENT

    if (bank === 'NEDBANK' || bank === 'NEDBANK CORPORATE SAVER ACCOUNT') {
      if (accountNumber && accountNumber.startsWith('9')) {
        accountType = '2'; // Savings
        this.debitOrderForm
          .get('branchCode')!
          .setValue('720026', { emitEvent: false });
        this.debitOrderForm
          .get('bank')!
          .setValue('NEDBANK CORPORATE SAVER ACCOUNT', { emitEvent: false });
      } else if (accountNumber && accountNumber.startsWith('1')) {
        accountType = '1'; // CHEQUE/CURRENT
        // Reset bank and branch code to NEDBANK and its default branch code
        this.debitOrderForm
          .get('branchCode')!
          .setValue('198765', { emitEvent: false });
        this.debitOrderForm
          .get('bank')!
          .setValue('NEDBANK', { emitEvent: false });
      } else if (accountNumber && accountNumber.startsWith('2')) {
        accountType = '2'; // Savings
        // Reset bank and branch code to NEDBANK and its default branch code
        this.debitOrderForm
          .get('branchCode')!
          .setValue('198765', { emitEvent: false });
        this.debitOrderForm
          .get('bank')!
          .setValue('NEDBANK', { emitEvent: false });
      }
    } else if (
      ['POSTBANK', 'CAPITEC BANK LIMITED', 'TYME BANK'].includes(bank)
    ) {
      accountType = '2'; // Savings
    }

    this.debitOrderForm
      .get('accountType')!
      .setValue(accountType, { emitEvent: false });
  }

  //Delete {
  async deleteProduct() {
    switch (this.collection) {
      case 'plan':
        await this.planService.deletePlan(this.selectedItem.id);
        break;
      case 'addOn':
        await this.addOnService.deleteAddOn(this.selectedItem.id);
    }
    this.closeDialog();
  }

  async updateProductScheduledPremiums() {
    if (
      this.scheduledPremiumForm.valid &&
      this.hasScheduledPremiumFormChanged()
    ) {
      this.closeDialog();
      if (this.router.url.startsWith('/plan-member-management/')) {
        await this.planService.updateSelectedPlanMemberTypePremium(
          this.scheduledPremiumForm.value,
          this.index
        );
      } else {
        await this.addOnService.updateSelectedAddOnPlanPremium(
          this.scheduledPremiumForm.value,
          this.index
        );
      }
    }
  }

  async deleteProductScheduledPremium() {
    this.closeDialog();
    if (this.router.url.startsWith('/plan-member-management/')) {
      await this.planService.updateSelectedPlanMemberTypePremium(
        this.selectedItem,
        this.index,
        true
      );
    } else {
      await this.addOnService.updateSelectedAddOnPlanPremium(
        this.selectedItem,
        this.index,
        true
      );
    }
  }

  async deleteAddOnPlan() {
    if (this.addOnService.selectedAddOn) {
      this.addOnService.updateAddOnPlans(
        this.addOnService.selectedAddOn,
        this.selectedItem,
        this.index,
        true
      );
      this.closeDialog();
    }
  }

  async deletePlanMember() {
    if (this.planService.selectedPlan)
      this.planService.updatePlanMembers(
        this.planService.selectedPlan,
        this.selectedItem,
        this.index,
        true
      );
    this.closeDialog();
  }

  async deletePolicy() {
    this.closeDialog();
    this.policyService.deletePolicy(this.selectedItem.id);
  }
  //Delete }

  //Create {
  // Use policyService updatePolicyComments to create/update a comment in policy collection
  async updateComments() {
    if (
      this.policyService.selectedPolicy &&
      this.rolesRightsService.currentUserRole?.policies?.update &&
      this.commentForm.valid &&
      this.commentForm.get('comment')?.value !== this.selectedItem?.comment
    ) {
      this.closeDialog();
      await this.policyService.updatePolicyComments(
        this.policyService.selectedPolicy,
        {
          comment: this.commentForm.get('comment')?.value,
        },
        this.index
      );
    }
  }
  //Create }

  // Use policyService updatePolicyComments to delete a comment out of policy collection
  async deletePolicyComment() {
    if (
      this.policyService.selectedPolicy &&
      this.rolesRightsService.currentUserRole?.policies?.delete
    ) {
      this.closeDialog();
      await this.policyService.updatePolicyComments(
        this.policyService.selectedPolicy,
        { comment: this.inputText },
        this.index,
        true
      );
    }
  }

  // Use policyService updatePolicyComments to delete a comment out of policy collection
  async deletePolicyMember() {
    if (
      this.policyService.selectedPolicy &&
      this.rolesRightsService.currentUserRole?.policies?.delete
    ) {
      this.closeDialog();
      await this.policyService.updatePolicyMembers(
        this.policyService.selectedPolicy,
        this.selectedItem,
        this.index,
        true
      );
    }
  }

  // Use policyService updatePolicyComments to delete a comment out of policy collection
  async deletePolicyAddOn() {
    if (
      this.policyService.selectedPolicy &&
      this.rolesRightsService.currentUserRole?.policies?.delete
    ) {
      this.closeDialog();
      await this.policyService.updatePolicyAddOns(
        this.policyService.selectedPolicy,
        this.selectedItem,
        this.index,
        true
      );
    }
  }

  // Use reportService deleteReport to delete a report out of report documents
  async deleteReport() {
    if (
      this.selectedItem.id &&
      this.userService.userData?.uid === this.selectedItem?.createdBy?.uid
    ) {
      this.closeDialog();
      await this.reportService.deleteReport(this.selectedItem.id);
    }
  }

  async deleteGeneratedReport() {
    if (this.selectedItem.id) {
      this.closeDialog();
      await this.reportService.deleteGeneratedReport(this.selectedItem.id);
    }
  }

  //Create {
  async updateMessages() {
    if (this.policyService.selectedPolicy)
      this.messageService.sendSMS(
        await this.policyService.getPolicyPrimaryMemberCellNum(),
        this.inputText ?? '',
        this.policyService.selectedPolicy.id ?? ''
      );
    this.closeDialog();
  }
  //Create }

  //Create {
  // Use fileService/policyService upload/updatePolicyFiles to create/update an upload in policy collection
  async updateFiles(customName: string) {
    if (this.inputText) {
      try {
        if (this.policyService.selectedPolicy)
          await this.policyService.updatePolicyFiles(
            this.policyService.selectedPolicy,
            {
              customName: customName,
            },
            this.index
          );
        this.inputText = undefined;
      } catch (err) {
        if (err instanceof Error)
          this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('ERROR UPDATING FILE TITLE');
      }
    } else {
      this.fileService.initiateFileUpload(customName);
    }
    this.closeDialog();
  }

  async updateMemberRequestedWaitingDate(requestedWaitingDate: Date) {
    const requestDate =
      requestedWaitingDate instanceof Date
        ? this.dateTimeService.dateToTimestamp(requestedWaitingDate)
        : requestedWaitingDate;
    if (
      requestDate &&
      requestDate.seconds != this.selectedItem.waitingDate.seconds &&
      this.policyService.selectedPolicy?.id
    ) {
      this.closeDialog();
      let requestData = {
        status: 'REQUESTED',
        requestedWaitingDate: requestDate,
        requestReason: 'WAITING DATE OVERRIDE',
      };
      await this.policyService.updatePolicyMembers(
        this.policyService.selectedPolicy,
        requestData,
        this.index
      );
    }
  }

  async updateAddOnRequestedWaitingDate(requestedWaitingDate: Date) {
    const requestDate =
      requestedWaitingDate instanceof Date
        ? this.dateTimeService.dateToTimestamp(requestedWaitingDate)
        : requestedWaitingDate;
    if (
      requestDate &&
      requestDate.seconds != this.selectedItem.waitingDate.seconds &&
      this.policyService.selectedPolicy?.id
    ) {
      this.closeDialog();
      let requestData = {
        status: 'REQUESTED',
        requestedWaitingDate: requestDate,
        requestReason: 'WAITING DATE OVERRIDE',
      };
      await this.policyService.updatePolicyAddOns(
        this.policyService.selectedPolicy,
        requestData,
        this.index
      );
    }
  }

  async updateUserLocation() {
    if (this.newLocationForm.valid) {
      let newUserLocation: UserLocation;
      if (this.selectedItem) {
        newUserLocation = {
          ...this.newLocationForm.value,
          id: this.selectedItem.id,
          status: 'ACTIVE',
        };
      } else {
        const id = uuidv4();
        newUserLocation = {
          ...this.newLocationForm.value,
          id: id,
          status: 'ACTIVE',
        };
      }

      this.closeDialog();
      await this.userService.updateUserLocation(newUserLocation);
    }
  }

  deleteUserLocation() {
    this.closeDialog();
    this.userService.deleteUserLocationById(this.selectedItem.id);
  }

  //Create }

  //Delete {
  async deleteFile() {
    if (this.addOnService.selectedAddOn) {
      this.addOnService.updateAddOnPlans(
        this.addOnService.selectedAddOn,
        this.selectedItem,
        this.index,
        true
      );
      this.closeDialog();
    }
  }
  //Delete }

  async changePolicyPlan() {
    let policyFormValue = this.policyService.selectedPolicy;

    if (
      this.selectedItem?.planId &&
      policyFormValue?.planId &&
      policyFormValue.productsPaymentStatus
    ) {
      policyFormValue.productsPaymentStatus.forEach((status) => {
        if (status.id === policyFormValue?.planId) {
          status.id = this.selectedItem.planId;
        }
      });
    }

    policyFormValue = {
      ...policyFormValue,
      ...this.selectedItem,
      status: PolicyStatus.IN_PROGRESS,
    };

    if (policyFormValue?.members) {
      policyFormValue.members = policyFormValue.members.map((member) => {
        if (member.status === MemberStatus.CLAIMED) {
          return member;
        } else {
          return {
            ...member,
            previousStatus:
              member.status !== MemberStatus.INACTIVE ? member.status : '',
            status: MemberStatus.INACTIVE,
          };
        }
      });
    }

    if (policyFormValue?.addOns)
      policyFormValue.addOns = policyFormValue.addOns.map((addOn) => ({
        ...addOn,
        previousStatus:
          addOn.status !== AddOnStatus.INACTIVE ? addOn.status : '',
        status: AddOnStatus.INACTIVE,
      }));

    this.closeDialog();
    this.filterService.filterClicked.policyAddOns = false;
    this.filterService.filterClicked.policyMembers = false;
    await this.policyService.updatePolicy(policyFormValue);
  }

  async togglePolicyStatusToInactive() {
    const newStatus =
      this.selectedItem.status !== 'INACTIVE' ? 'INACTIVE' : 'ACTIVE';

    let policyFormValue = this.policyService.selectedPolicy;

    if (policyFormValue?.members) {
      policyFormValue.members = policyFormValue.members.map((member) => {
        if (member.status === 'CLAIMED') {
          return member;
        } else {
          return {
            ...member,
            status: 'INACTIVE',
          };
        }
      });
    }
    if (policyFormValue?.addOns)
      policyFormValue.addOns = policyFormValue.addOns.map((addOn) => ({
        ...addOn,
        status: 'INACTIVE',
      }));

    this.filterService.filterClicked.policyAddOns = false;
    this.filterService.filterClicked.policyMembers = false;
    this.closeDialog();

    await this.policyService.setSelectedPolicy(this.selectedItem.id);
    await this.policyService.updatePolicy({
      ...policyFormValue,
      ...{ status: newStatus },
    });
  }

  canUploadOrUpdateFile(customName: string) {
    const selectedFileExists = this.fileService.selectedFile;
    const inputTextExists = this.inputText;
    const policyFileDoesNotMatchCustomName =
      this.policyService.selectedPolicy?.files?.[this.index]?.customName !==
      customName;

    return (
      (!inputTextExists && selectedFileExists && customName) ||
      (inputTextExists && customName && policyFileDoesNotMatchCustomName)
    );
  }

  async createUserRole(roleName: string) {
    const newRole = {
      name: roleName,
      status: 'ACTIVE',
      users: { read: false, update: false, delete: false },
      policies: {
        read: false,
        update: false,
        delete: false,
        approveRequest: false,
        override: false,
        claim: false,
      },
      products: { read: false, update: false, delete: false },
    };

    this.closeDialog();
    await this.rolesRightsService.createRole(newRole);
  }

  async changeMemberToClaimed() {
    if (this.policyService.selectedPolicy) {
      this.policyLogService.claim = true;
      await this.policyService.updatePolicyMembers(
        this.policyService.selectedPolicy,
        {
          ...this.selectedItem,
          ...{ status: 'CLAIMED' },
        },
        this.index,
        false
      );
    }
  }

  async revertMemberClaimedStatus() {
    this.policyService.refreshAllowedMemberTypes();

    if (
      this.policyService.selectedPolicy &&
      this.policyService.allowedMemberTypes?.find(
        (memberType) => memberType.id === this.selectedItem.memberTypeId
      )
    ) {
      await this.policyService.updatePolicyMembers(
        this.policyService.selectedPolicy,
        { ...this.selectedItem, ...{ status: MemberStatus.ACTIVE } },
        this.index,
        false
      );
      this.policyService.refreshAllowedMemberTypes();
    } else {
      const memberType = this.planService.getMemberTypeById(
        this.selectedItem.memberTypeId
      );
      if (memberType.name && memberType.maxAllowed) {
        const pluralizedName = this.mainService.pluralize(
          memberType.name,
          memberType.maxAllowed
        );

        this.snackBarService.openRedSnackBar(
          `MAX OF ${memberType.maxAllowed} ACTIVE ${pluralizedName} REACHED!`
        );
      }
    }
  }

  async searchReferenceNumberDuplicate(searchText: any) {
    if (this.policyService.selectedPolicy?.id) {
      this.mainService.setLoading(true);

      const referenceNumberExists =
        await this.validationService.checkReferenceNumberExists(
          searchText,
          this.policyService.selectedPolicy?.id
        );

      if (!referenceNumberExists) {
        this.policyLogService.currentRefNum = searchText;
      } else if (referenceNumberExists.policyId) {
        this.snackBarService.openRedSnackBar(
          'REFERENCE NUMBER ' +
            searchText +
            ' ALREADY EXISTS ON POLICY ' +
            (
              await this.policyService.getPolicyById(
                referenceNumberExists.policyId
              )
            ).policyNumber
        );
      }
      this.mainService.setLoading(false);
    }
  }

  async searchPolicies(target: any) {
    this.mainService.setLoading(true);
    this.searchText = target.value;
    this.searchService.searchText.moveTransaction = this.searchText;
    if (this.searchText == '') {
      this.searchService.dataSourceSearchMoveTransaction = null;
    } else {
      const searchResponse = await this.searchService.policySearch(
        this.searchText
      );

      const rawHits = searchResponse.hits;

      const promises: Promise<MemberPolicySearch>[] = rawHits.flatMap(
        (hit: Policy) =>
          hit.members?.map(async (member: Member) => {
            let plan = '(NOT FOUND)';
            if (hit.planId) {
              plan =
                (await this.planService.getPlanNameById(hit.planId))?.text ??
                plan;
            }
            return {
              policyNumber: hit.policyNumber,
              ...member,
              plan: plan,
              policyId: hit.id,
            } as MemberPolicySearch;
          }) ?? []
      );

      this.policyHits = await Promise.all(promises);
      const filteredPolicyHits = this.policyService.selectedPolicy
        ? this.policyHits.filter(
            (policy) =>
              policy.policyNumber !==
              this.policyService.selectedPolicy?.policyNumber
          )
        : this.policyHits;

      if (filteredPolicyHits.length === 0) {
        // If there are no results, create a single "NO RESULTS FOUND" record
        this.searchService.dataSourceSearchMoveTransaction =
          new MatTableDataSource([
            {
              policyNumber: 'NO RESULTS FOUND',
              idNumber: '-',
              firstName: '-',
              lastName: '-',
              plan: '-',
              status: '-',
            },
          ]);
      } else {
        // Filter the results

        this.searchService.dataSourceSearchMoveTransaction =
          new MatTableDataSource(filteredPolicyHits);
        this.searchService.dataSourceSearchMoveTransaction.filter =
          this.searchText.trim().toLowerCase();
      }
    }
    this.mainService.setLoading(false);
  }

  clearSearch() {
    this.searchText = '';
    this.searchService.searchText.moveTransaction = '';
    this.searchService.dataSourceSearchMoveTransaction = null;
  }

  setSelectPolicy(policy: any) {
    this.selectedPolicy = policy;
  }

  overrideMemberId() {
    this.searchService.overrideMemberId = true;
  }

  overrideMemberIdAge() {
    this.planService.overrideMemberIdAge = true;
  }

  // requestOverrideMemberIdAge() {
  //   this.planService.requestOverrideMemberIdAge = true;

  //   let requestData = {
  //     ...this.formValues,
  //     status: 'REQUESTED',
  //     requestReason: 'AGE OVERRIDE',
  //   };
  //   if (this.policyService.selectedPolicy)
  //     this.policyService.updatePolicyMembers(
  //       this.policyService.selectedPolicy,
  //       requestData,
  //       -1
  //     );
  //   this.closeDialog();
  // }

  async onDuplicateIdNumberRowClick(policyNumber: string, event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }

    const policy = await this.policyService.getPolicyByPolicyNumber(
      policyNumber
    );
    const route = ['/policy-summary', policy.id];

    if (event) {
      const url = this.router.createUrlTree(route).toString();
      window.open(url, '', 'noopener,noreferrer');
    } else {
      this.router.navigate(route);
    }
  }

  async createManualTransaction(allocation: boolean = false) {
    if (
      this.manualReceiptForm.controls['amount'].valid &&
      this.policyService.selectedPolicy &&
      !(
        allocation &&
        (this.policyService.selectedPolicy.status === 'INACTIVE' ||
          this.policyService.selectedPolicy.status === 'LAPSED')
      )
    ) {
      this.mainService.setLoading(true);

      const status = allocation
        ? TransactionStatus.ALLOCATED
        : TransactionStatus.PAID;
      this.manualReceiptForm.value.method = allocation
        ? PaymentMethod.SYSTEM_ALLOCATION
        : PaymentMethod.ONLINE_CASH;

      const amount = this.manualReceiptForm.get('amount')?.value ?? 0;

      try {
        await this.transactionService.updateTransactions(
          {
            ...this.manualReceiptForm.value,
            ...{ status, amount },
          },
          this.periods
        );

        if (allocation) {
          await this.policyService.updatePolicyUnallocatedBalance(
            this.policyService.selectedPolicy,
            -amount
          );
          this.policyService.selectedPolicyUnallocatedBalanceUpdated.emit();
        }
        const route = [
          '/transaction-summary',
          this.transactionService.selectedTransaction?.id,
        ];
        const url = this.router.createUrlTree(route).toString();
        window.open(url, '', 'noopener,noreferrer');
      } catch (err) {
      } finally {
        this.mainService.setLoading(false);
      }
    }
    this.closeDialog();
  }

  async updateDebitOrder() {
    if (
      this.debitOrderForm.valid &&
      this.rolesRightsService.currentUserRole?.debitOrders?.update &&
      this.policyService.selectedPolicy &&
      !(
        this.policyService.selectedPolicy.status === 'INACTIVE' ||
        this.policyService.selectedPolicy.status === 'LAPSED'
      )
    ) {
      await this.searchReferenceNumberDuplicate(
        this.debitOrderForm.value.referenceNumber
      );
      if (
        this.policyLogService.currentRefNum ==
          this.debitOrderForm.value.referenceNumber &&
        this.policyService.selectedPolicy.policyNumber
      ) {
        let validAccountNumber =
          await this.validationService.validateBankAccount(
            this.debitOrderForm.value.accountNumber,
            this.debitOrderForm.value.branchCode,
            this.debitOrderForm.value.accountType
          );

        if (validAccountNumber) {
          await this.policyService.updatePolicyDebitOrder(
            this.policyService.selectedPolicy,
            this.debitOrderForm.value
          );
          this.closeDialog();
        } else {
          this.snackBarService.openRedSnackBar(
            this.debitOrderForm.value.accountNumber +
              ' IS NOT A VALID ACCOUNT NUMBER!'
          );
        }
      }
    }
  }

  async retryNetcashBatch() {
    this.closeDialog();
    await this.transactionService.retryNetcashBatch(this.selectedItem);
  }

  async updateOfflineTransactions() {
    let receiptReferenceNumber = this.offLineReceiptForm.get(
      'receiptReferenceNumber'
    )?.value;
    let duplicateReceipt =
      await this.validationService.searchDuplicateReceiptReferenceNumber(
        receiptReferenceNumber
      );

    if (!duplicateReceipt) {
      if (this.offLineReceiptForm.valid) this.closeDialog();
      const amount = Number(this.offLineReceiptForm.get('amount')?.value);
      const allocated = this.policyService.selectedPolicy
        ? this.periods.some((period) => period.premium === amount)
        : false;
      const status = allocated
        ? TransactionStatus.PAID
        : this.policyService.selectedPolicy
        ? TransactionStatus.UNALLOCATED
        : TransactionStatus.UNIDENTIFIED;
      await this.transactionService.updateTransactions(
        {
          ...this.offLineReceiptForm.value,
          ...{ status, amount },
        },
        this.periods
      );
      this.transactionService.offlineReceiptDate =
        this.offLineReceiptForm.get('transactionDate')?.value;
      this.transactionService.offlineReceiptCounter = (
        Number(this.offLineReceiptForm.get('receiptReferenceNumber')?.value) + 1
      ).toString();
      this.closeDialog();
    } else {
      this.snackBarService.openRedSnackBar(
        'RECEIPT NUMBER ' + receiptReferenceNumber + ' ALREADY EXISTS!'
      );
    }
  }

  offlineReceiptOnAmountChange(amount: EventTarget | null) {
    let input = amount as HTMLInputElement;
    if (input.value && input.value != '' && Number(input.value) != 0) {
      this.updatePeriodsPaid(Number(input.value));
    } else {
      this.periodsPaid = 'ENTER A AMOUNT';
      this.offlineReceiptStatus = '';
    }
  }

  updatePeriodsPaid(amount: Number) {
    let payments;
    const periodIndex = this.periods.findIndex((period: any) => {
      return period.premium === amount;
    });

    payments =
      periodIndex != -1
        ? this.periods[periodIndex].payments.slice(0, periodIndex + 1)
        : [];
    this.periodsPaid = this.transactionService.displayPeriodPaid(
      this.transactionService.getTransactionPeriodsPaid(payments)
    );

    if (periodIndex == -1) {
      this.offlineReceiptStatus =
        this.transactionService.getTransactionStatusTextAndClass('UNALLOCATED');
    } else {
      this.offlineReceiptStatus =
        this.transactionService.getTransactionStatusTextAndClass('PAID');
    }
  }

  async moveTransaction() {
    if (this.selectedPolicy.policyId && this.selectedItem) {
      this.closeDialog();
      if (this.selectedItem.status !== 'UNALLOCATED FUNDS AMENDMENT') {
        this.transactionService.reverseOrMoveTransaction(
          this.selectedItem,
          '',
          true,
          this.selectedPolicy.policyId
        );
      } else {
        await this.transactionService.amendUnallocatedFunds(
          this.selectedItem,
          this.addFunds,
          '',
          this.selectedPolicy.policyId
        );
      }
    }
  }

  async handleUnallocatedFundsTransfer() {
    if (
      this.transactionService.isValidUnallocatedFunds(
        this.getNewUnallocatedAmount()
      )
    ) {
      this.selectedItem = {
        amount: this.amendUnallocatedFundsForm.get('amount')?.value,
        transactionDate: Timestamp.now(),
        method: 'SYSTEM ALLOCATION',
        status: 'UNALLOCATED FUNDS AMENDMENT',
        payments: [],
      };
      this.dialogType = 'transferTransactionDialog';
      this.dataSourceSelectedTransaction = new MatTableDataSource([
        this.selectedItem,
      ]);
    }
  }

  // Get member type name by member type id
  getMemberTypeNameById(member: Member) {
    if (this.policyService.selectedPolicy?.planId && member.memberTypeId) {
      return this.policyService.getMemberTypeNameAndClassById(
        member.memberTypeId
      );
    }
    return { text: '(NOT FOUND)', class: ['italic'] };
  }

  getPremiumMonth(value: number): string {
    const periods = 12;
    const premiums = this.transactionService.getPolicyPremiumPeriods(periods);

    for (let premium of premiums) {
      if (value === premium.premium) {
        return premium.month;
      }
    }

    return '';
  }

  logOut() {
    this.closeDialog();
    this.userService.signOut();
  }

  getNewUnallocatedAmount() {
    if (
      this.policyService.selectedPolicy?.unallocatedBalance !== undefined &&
      this.amendUnallocatedFundsForm.get('amount')?.value !== undefined &&
      this.amendUnallocatedFundsForm.get('amount')?.value !== '' &&
      this.amendUnallocatedFundsForm.get('amount')?.value !== 0
    ) {
      return this.addFunds
        ? this.policyService.selectedPolicy?.unallocatedBalance +
            this.amendUnallocatedFundsForm.get('amount')?.value
        : this.policyService.selectedPolicy?.unallocatedBalance -
            this.amendUnallocatedFundsForm.get('amount')?.value;
    } else {
      return undefined;
    }
  }

  private getWaitingPeriod(): number | undefined {
    if (this.selectedItem.addOnId) {
      const addOn = this.addOnService.getAddOnById(this.selectedItem.addOnId);
      const planForAddon = addOn?.plans?.find(
        (addOnPlan) =>
          addOnPlan.planId === this.policyService.selectedPolicy?.planId
      );
      return planForAddon?.waitingPeriod;
    }
    return this.planService.getMemberTypeById(this.selectedItem?.memberTypeId)
      ?.waitingPeriod;
  }

  getNewWaitingPeriodLeft(newWaitingPeriod: any): string {
    const now = this.dateTimeService.getZeroHourDate();

    const newWaitingDate = this.selectedItem.addOnId
      ? this.policyService.calculateAddOnWaitingDate(
          newWaitingPeriod,
          this.selectedItem
        )
      : this.policyService.calculateMemberWaitingDate(
          newWaitingPeriod,
          this.selectedItem
        );

    if (!newWaitingDate || !newWaitingPeriod) return '-';

    const waitingPeriod = this.getWaitingPeriod();
    if (waitingPeriod !== undefined) {
      const daysDifference = this.dateTimeService.getDaysDifference(
        this.dateTimeService.dateToTimestamp(now) ?? Timestamp.now(),
        newWaitingDate
      );
      const waitingPeriodLeft = Number(waitingPeriod - daysDifference);
      return waitingPeriodLeft > 0 ? `${waitingPeriodLeft}` : `NO`;
    }

    return '-';
  }

  getNewWaitingPeriodLeftByDate(newWaitingDate: any): string {
    const now = this.dateTimeService.getZeroHourDate();

    if (!newWaitingDate) return '-';
    if (newWaitingDate && newWaitingDate instanceof Date)
      newWaitingDate = this.dateTimeService.dateToTimestamp(newWaitingDate);

    const waitingPeriod = this.getWaitingPeriod();
    if (waitingPeriod !== undefined) {
      const daysDifference = this.dateTimeService.getDaysDifference(
        this.dateTimeService.dateToTimestamp(now) ?? Timestamp.now(),
        newWaitingDate
      );
      const waitingPeriodLeft = Number(waitingPeriod - daysDifference);
      return waitingPeriodLeft > 0 ? `${waitingPeriodLeft}` : `NO`;
    }

    return '-';
  }

  toggleAddFunds() {
    this.addFunds = !this.addFunds;
    this.amendUnallocatedFundsForm.get('additionReason')?.setValue('');
    this.amendUnallocatedFundsForm.get('reverseReason')?.setValue('');
  }

  shouldDisableAddReverseButton(): boolean {
    const validUnallocatedFunds =
      this.transactionService.isValidUnallocatedFunds(
        this.getNewUnallocatedAmount()
      );
    const additionReasonInvalid =
      this.amendUnallocatedFundsForm.controls['additionReason'].invalid;
    const reverseReasonInvalid =
      this.amendUnallocatedFundsForm.controls['reverseReason'].invalid;
    const amountValue = this.amendUnallocatedFundsForm.get('amount')?.value;

    const invalidAmountValue = amountValue && amountValue <= 0;

    if (this.addFunds) {
      return invalidAmountValue || additionReasonInvalid;
    } else {
      return (
        invalidAmountValue || !validUnallocatedFunds || reverseReasonInvalid
      );
    }
  }

  monthYearSelected(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
    const newDate = new Date(
      normalizedMonth.getFullYear(),
      normalizedMonth.getMonth(),
      this.dateTimeService
        .timestampToDate(this.selectedItem.lastPeriodPaid)
        ?.getDate()
    );

    this.lastPeriodPaidForm.get('lastPeriodPaid')?.setValue(newDate);

    datepicker.close();
  }

  async handleProductPaymentStatusOverride() {
    if (
      this.lastPeriodPaidForm.valid &&
      this.dateTimeService.hasDateChanged(
        this.lastPeriodPaidForm.get('lastPeriodPaid')?.value,
        this.selectedItem.lastPeriodPaid
      ) &&
      this.rolesRightsService.currentUserRole?.transactions?.amendLastPeriodPaid
    ) {
      const productPaymentStatus = {
        ...this.selectedItem,
        lastPeriodPaid: this.dateTimeService.dateToTimestamp(
          this.lastPeriodPaidForm.get('lastPeriodPaid')?.value
        ),
      };
      const willLapsePolicy =
        this.transactionService.willChangeLapsePolicy(productPaymentStatus);

      if (willLapsePolicy) {
        this.openLastPeriodPaidWarningDialog(productPaymentStatus);
        return;
      }

      this.productPaymentStatusToOverride = productPaymentStatus;
      await this.executeProductPaymentStatusOverride();
    } else {
      this.snackBarService.openRedSnackBar('PERIOD REQUESTED INVALID');
    }
  }

  openLastPeriodPaidWarningDialog(productPaymentStatus: ProductPaymentStatus) {
    this.dialogType = 'lastPeriodPaidWarningDialog';
    this.dialogTitle = 'THE POLICY WILL LAPSE';
    this.productPaymentStatusToOverride = productPaymentStatus;
  }

  async executeProductPaymentStatusOverride() {
    this.closeDialog();
    if (this.productPaymentStatusToOverride) {
      await this.transactionService.overrideProductPaymentStatus(
        this.productPaymentStatusToOverride,
        this.lastPeriodPaidForm.get('reason')?.value ?? ''
      );
    }
  }

  async handleIntendedPaymentDayOverride() {
    if (
      this.intendedPaymentDayForm.valid &&
      this.intendedPaymentDayForm.get('intendedPaymentDay')?.value !==
        this.selectedItem.intendedPaymentDay &&
      this.rolesRightsService.currentUserRole?.transactions?.amendLastPeriodPaid
    ) {
      this.closeDialog();
      await this.policyService.updateIntendedPaymentDay(
        this.selectedItem,
        this.intendedPaymentDayForm.get('intendedPaymentDay')?.value,
        this.intendedPaymentDayForm.get('reason')?.value
      );
    }
  }

  hasScheduledPremiumFormChanged() {
    if (!this.selectedItem) {
      return true;
    }

    const startDateChanged = this.dateTimeService.hasDateChanged(
      this.scheduledPremiumForm.get('startDate')?.value,
      this.selectedItem.startDate
    );
    return (
      startDateChanged ||
      this.scheduledPremiumForm.get('standardAmount')?.value !==
        this.selectedItem.standardAmount ||
      this.scheduledPremiumForm.get('waitingAmount')?.value !==
        this.selectedItem.waitingAmount
    );
  }

  hasCashUpFormChanged() {
    if (!this.selectedItem) {
      return true;
    }

    return (
      this.cashUpForm.get('cashDeclared')?.value !==
        this.selectedItem.cashDeclared ||
      this.cashUpForm.get('cardDeclared')?.value !==
        this.selectedItem.cardDeclared ||
      this.cashUpForm.get('comment')?.value !== this.selectedItem.comment
    );
  }

  openDeleteCashUpDialog(element: CashUp) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialogType: 'deleteCashUpDialog',
      selectedItem: element,
    };
    this.dialog.open(DialogComponent, dialogConfig);
    this.closeDialog();
  }

  hasDebitOrderFormChanged() {
    if (!this.selectedItem.debitOrder) {
      return true;
    }

    const formValue = this.debitOrderForm.getRawValue(); // Assuming this is how you get your form values

    return Object.keys(formValue).some((key) => {
      // Ensure the key is a keyof DebitOrder to satisfy TypeScript type checking
      return (
        formValue[key] !== this.selectedItem.debitOrder[key as keyof DebitOrder]
      );
    });
  }

  async reportNameDuplicateCheck(reportName: string) {
    this.isDuplicateReportName =
      await this.reportService.reportConfigNameExists(reportName);
  }
}
