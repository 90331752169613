import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  GeneratedReport,
  ReportConfig,
  ReportConfigs,
  ReportType,
  UserReports,
} from '../models/report.model';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SnackBarService } from './snack-bar.service';
import { uuidv4 } from '@firebase/util';
import { UserService } from './user.service';
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  DocumentReference,
  getDoc,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { TransactionService } from './transaction.service';
import { RolesRightsService } from './roles-rights.service';
import { DateTimeService } from './date-time.service';
import { PlanService } from './plan.service';

import { PolicyService } from './policy.service';
import { AddOnService } from './add-on.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MainService } from './main.service';
import { FilterService } from './filter.service';
import { FileData } from '../models/file.model';
import { FileService } from './file.service';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { CreatedBy } from '../models/general.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private dbModular = getFirestore();
  private fireFunctions = getFunctions(undefined, 'europe-west3');

  constructor(
    private userService: UserService,
    private fileService: FileService,
    private rolesRightsService: RolesRightsService,
    public filterService: FilterService,
    public mainService: MainService,
    private snackBarService: SnackBarService,
    private http: HttpClient,
    private router: Router
  ) {
    this.userService.destroy$.pipe().subscribe(() => this.cleanUp());
  }
  private destroy$ = new Subject<void>(); // Used for unsubscribing when the component is destroyed
  public allReportDocs: ReportConfigs[] | undefined;
  public allReportConfigs: ReportConfig[] = [];
  filteredReportConfigs: ReportConfig[] = [];
  selectedReport: ReportConfig | undefined;

  reportConfigFilter: ReportType = ReportType.ALL;
  searchTextReportConfigs: string = '';
  searchTextGeneratedReports: string = '';
  generatedReportFilter: ReportType = ReportType.ALL;

  loading: boolean = false;

  dataSourceReportConfigs: MatTableDataSource<ReportConfig>;

  userReportExtracts: MatTableDataSource<any>;

  selectedGeneratedReports: UserReports | undefined;
  allGeneratedReports: GeneratedReport[] | undefined;
  filteredGeneratedReports: GeneratedReport[] = [];

  generatedReportDoc: DocumentReference<UserReports>;
  selectedGeneratedReports$: Observable<UserReports | undefined>;

  selectedGeneratedReportsUpdated = new EventEmitter<void>();
  dataSourceGeneratedReports: MatTableDataSource<GeneratedReport>;

  @Output() loadedReportConfigsUpdated = new EventEmitter<void>();

  async setSelectedReport(reportId: string, isExtraction: boolean = false) {
    if (!this.allReportDocs) {
      await this.loadReportConfigs();
    }

    const report = this.allReportConfigs.find(
      (config) => config.id === reportId
    );

    if (this.doesOwnReport(report) || isExtraction) {
      this.selectedReport = report;
    } else {
      this.resetSelectedReport();
      this.router.navigate(['/reports']);
    }
  }

  async loadReportConfigs() {
    return new Promise<void>((resolve, reject) => {
      const reportCollection = collection(this.dbModular, 'report');
      const reportQuery = query(reportCollection);
      const unsubscribe = onSnapshot(
        reportQuery,
        (snapshot) => {
          const reports = snapshot.docs.map((doc) => ({
            ...(doc.data() as ReportConfigs),
            id: doc.id,
          }));

          this.allReportDocs = reports; // Save all documents here
          const configs = reports
            .flatMap((report) => report.config || []) // Flatten all config arrays
            .sort((a, b) => {
              // Sorting logic remains the same
              if (a.updatedOn && b.updatedOn) {
                return (
                  b.updatedOn.toDate().getTime() -
                  a.updatedOn.toDate().getTime()
                );
              }
              if (a.updatedOn) return -1;
              if (b.updatedOn) return 1;
              return 0;
            });

          this.allReportConfigs = configs.filter(
            (config) =>
              !config.isPrivate ||
              (config.isPrivate &&
                config.createdBy?.uid === this.userService.userData?.uid)
          );

          this.applyFilter();
          this.loadedReportConfigsUpdated.emit();
          resolve();
        },
        (error) => {
          this.snackBarService.latestError = error.message;
          this.snackBarService.openRedSnackBar(
            'LOADING ALL REPORT CONFIGS FAILED!'
          );
          reject(error);
        }
      );

      this.destroy$.subscribe(() => unsubscribe());
    });
  }

  reportConfigNameExists(reportName: string): boolean {
    // Assuming allReportConfigs is an array of ReportConfig and is populated
    if (this.allReportConfigs && this.allReportConfigs.length > 0) {
      // Check if any report config has the same name (considering case sensitivity)
      const exists = this.allReportConfigs.some(
        (config) => config.reportName === reportName
      );

      return exists;
    }

    return false;
  }

  async createReportConfig(formValues: ReportConfig) {
    try {
      this.loading = true;
      const newConfigId = uuidv4(); // Generate a unique ID

      // Ensure currentUser is defined
      const currentUser = this.userService.userData;
      if (!currentUser) {
        throw new Error('Current user is undefined');
      }

      if (
        this.rolesRightsService.currentUserRole?.transactions?.export ||
        this.rolesRightsService.currentUserRole?.policies?.export
      ) {
        // Extract formValues without reportFormat if the report type is POLICY_LOG or MESSAGE
        const { reportFormat, editType, ...otherFormValues } = formValues;

        // Prepare the base config without the report format
        const newReportConfigBase: ReportConfig = {
          ...otherFormValues, // Use form values excluding reportFormat
          id: newConfigId,
          lastExtractionDate: Timestamp.now(),
          createdOn: Timestamp.now(),
          updatedOn: Timestamp.now(),
          createdBy: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            email: currentUser.email,
            userLocationId: currentUser.currentUserLocationId,
          },
          updatedBy: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            email: currentUser.email,
            userLocationId: currentUser.currentUserLocationId,
          },
        };

        // Check if we need to add reportFormat (for types other than POLICY_LOG or MESSAGE)
        let newReportConfig = newReportConfigBase;

        if (
          formValues.reportType !== ReportType.POLICY_LOG &&
          formValues.reportType !== ReportType.MESSAGE
        ) {
          newReportConfig = {
            ...newReportConfigBase,
            reportFormat, // Add reportFormat if the report type requires it
          };
        }

        if (
          formValues.reportType === ReportType.POLICY ||
          formValues.reportType === ReportType.MEMBER
        ) {
          newReportConfig = {
            ...newReportConfigBase,
            reportFormat,
            editType, // Add editType if the report type requires it
          };
        }

        if (this.allReportDocs && this.allReportDocs.length > 0) {
          // Find the document with the newest 'createdOn'
          const latestDoc = this.allReportDocs.reduce(
            (latest: ReportConfigs | undefined, doc: ReportConfigs) =>
              !latest ||
              (doc.createdOn &&
                latest.createdOn &&
                doc.createdOn.toDate() > latest.createdOn.toDate())
                ? doc
                : latest,
            undefined
          );

          if (latestDoc?.id) {
            // Check the length of the config array
            if (latestDoc.config && latestDoc.config.length >= 350) {
              // Sort by 'lastExtractionDate' and remove the oldest entry
              latestDoc.config.sort((a, b) => {
                const aDate = a.lastExtractionDate
                  ? a.lastExtractionDate.toDate().getTime()
                  : 0;
                const bDate = b.lastExtractionDate
                  ? b.lastExtractionDate.toDate().getTime()
                  : 0;
                return aDate - bDate;
              });
              latestDoc.config.shift(); // Removes the oldest config
            }

            // Append the new config to the document's config array
            const updatedConfigs = [
              ...(latestDoc.config || []),
              newReportConfig,
            ];
            const latestDocRef = doc(this.dbModular, 'report', latestDoc.id);

            // Update the document in Firestore
            await updateDoc(latestDocRef, { config: updatedConfigs });
          }
        }

        await this.setSelectedReport(newConfigId);
        this.router.navigate(['/report-details', newConfigId]);
        this.snackBarService.openBlueSnackBar('REPORT CREATED SUCCESSFULLY');
        this.loading = false;
      }
    } catch (err) {
      this.loading = false;
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ERROR CREATING REPORT CONFIG!');
    }
  }

  async updateReport(formValue: ReportConfig): Promise<void> {
    try {
      const currentUser = this.userService.userData;
      if (!currentUser) {
        throw new Error('Current user is undefined');
      }

      const updatedBy = {
        uid: currentUser.uid,
        displayName: currentUser.displayName,
        email: currentUser.email,
        userLocationId: this.userService.userData?.currentUserLocationId,
      };

      // Assuming you have the ID of the ReportConfig to update
      const reportConfigId = formValue.id;

      if (!reportConfigId) {
        throw new Error('Report configuration ID is undefined');
      }

      // Find the document containing the ReportConfig
      const reportConfigDoc = this.allReportDocs?.find((doc) =>
        doc.config?.some((config) => config.id === reportConfigId)
      );

      if (!reportConfigDoc?.id) {
        throw new Error('Report configuration document not found');
      }

      // Destructure formValue to remove reportFormat if reportType is POLICY_LOG or MESSAGE
      const { reportFormat, editType, ...otherFormValues } = formValue;
      let updatedFormValue: ReportConfig = otherFormValues;

      // Include reportFormat only for certain report types
      if (
        formValue.reportType !== ReportType.POLICY_LOG &&
        formValue.reportType !== ReportType.MESSAGE
      ) {
        updatedFormValue = {
          ...otherFormValues,
          reportFormat, // Add reportFormat if it's a valid type
        };
      }

      // Include editType for POLICY and MEMBER report types
      if (
        formValue.reportType === ReportType.POLICY ||
        formValue.reportType === ReportType.MEMBER
      ) {
        updatedFormValue = {
          ...otherFormValues,
          reportFormat,
          editType,
        };
      }

      // Update the specific ReportConfig
      const updatedConfigs = reportConfigDoc.config?.map((config) => {
        if (config.id === reportConfigId) {
          return {
            ...config,
            ...updatedFormValue, // Use the updated form values
            updatedBy,
            updatedOn: Timestamp.now(),
          };
        }
        return config;
      });

      // Update the document in Firestore
      const reportConfigDocRef = doc(
        this.dbModular,
        'report',
        reportConfigDoc.id
      );

      await updateDoc(reportConfigDocRef, { config: updatedConfigs });

      this.snackBarService.openBlueSnackBar('REPORT CONFIGURATION UPDATED');
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ERROR UPDATING REPORT CONFIG!');
    }
  }

  async updateLastExtractionDate(
    reportConfigId: string,
    lastExtractionDate: Timestamp
  ): Promise<void> {
    try {
      if (!reportConfigId) {
        throw new Error('Report configuration ID is undefined');
      }

      // Find the document containing the ReportConfig
      const reportConfigDoc = this.allReportDocs?.find((doc) =>
        doc.config?.some((config) => config.id === reportConfigId)
      );

      if (!reportConfigDoc?.id) {
        throw new Error('Report configuration document not found');
      }

      // Update only the lastExtractionDate for the specific ReportConfig
      const updatedConfigs = reportConfigDoc.config?.map((config) => {
        if (config.id === reportConfigId) {
          return {
            ...config, // Keep all existing config properties
            lastExtractionDate: lastExtractionDate, // Update only lastExtractionDate
          };
        }
        return config;
      });

      // Update the document in Firestore
      const reportConfigDocRef = doc(
        this.dbModular,
        'report',
        reportConfigDoc.id
      );

      await updateDoc(reportConfigDocRef, { config: updatedConfigs });
    } catch (err) {
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar(
        'ERROR UPDATING REPORT LAST EXTRACTION DATE!'
      );
    }
  }

  async deleteReport(reportId: string): Promise<void> {
    // Ensure allReportConfigs is loaded
    if (this.allReportConfigs.length === 0) {
      await this.loadReportConfigs();
    }

    // Find the document containing the ReportConfig to delete
    const reportConfigDoc = this.allReportDocs?.find((doc) =>
      doc.config?.some((config) => config.id === reportId)
    );

    if (reportConfigDoc?.id) {
      // Filter out the ReportConfig to be deleted
      const updatedConfigs =
        reportConfigDoc.config?.filter((config) => config.id !== reportId) ||
        [];

      try {
        // Update the document in Firestore without the deleted ReportConfig
        const reportConfigDocRef = doc(
          this.dbModular,
          'report',
          reportConfigDoc.id
        );

        await updateDoc(reportConfigDocRef, { config: updatedConfigs });

        // Remove the report from the local array
        this.allReportConfigs = this.allReportConfigs.filter(
          (config) => config.id !== reportId
        );

        this.snackBarService.openBlueSnackBar('REPORT CONFIGURATION DELETED');
      } catch (err) {
        if (err instanceof Error)
          this.snackBarService.latestError = err.message;
        this.snackBarService.openRedSnackBar('ERROR DELETING REPORT CONFIG!');
      }
    } else {
      this.snackBarService.openRedSnackBar(
        'REPORT CONFIGURATION DOCUMENT NOT FOUND!'
      );
    }
  }

  async createNewGeneratedReport(reportConfig: ReportConfig) {
    try {
      // Generate a new ID for the report
      const reportId = uuidv4();

      const currentUser = this.userService.userData;

      if (!currentUser?.uid) {
        throw new Error('Current user is undefined');
      }

      const createdBy: CreatedBy = {
        uid: currentUser.uid,
        displayName: currentUser.displayName,
        email: currentUser.email,
        userLocationId: currentUser?.currentUserLocationId,
        cellNumber: currentUser.cellNumber ?? '',
      };

      // Create the report document
      const newReport: Partial<GeneratedReport> = {
        id: reportId,
        reportName: reportConfig.reportName,
        reportType: reportConfig.reportType,
        status: 'PENDING',
        reportUrl: '',
        createdOnDateRange: reportConfig.createdOnDateRange,
        selectedCreatedByUser: reportConfig.selectedCreatedByUser,
        checkboxConfig: reportConfig.checkboxConfig,
        csvLines: 0,
        isCancelled: false,
        createdOn: Timestamp.now(),
        createdBy,
      };

      if (reportConfig.reportFormat) {
        newReport.reportFormat = reportConfig.reportFormat;
      }

      if (reportConfig.editType) {
        newReport.editType = reportConfig.editType;
      }

      if (!reportConfig.createdBy?.uid) {
        throw new Error('Report creator not found');
      }

      // Reference to the generated report document
      const reportRef = doc(this.dbModular, 'generatedReport', currentUser.uid);

      // Check if the document exists
      const reportDoc = await getDoc(reportRef);
      if (!reportDoc.exists()) {
        // If the document does not exist, create a new document with an initial empty structure
        await setDoc(reportRef, {
          generatedReports: [],
        });
      }

      // Add the new report to the generatedReports array
      await setDoc(
        reportRef,
        {
          generatedReports: arrayUnion(newReport),
        },
        { merge: true } // Merge ensures existing data is preserved and updated
      );

      // Return the report ID for later use (e.g., to update it after the report is generated)
      return newReport;
    } catch (err) {
      console.error('Error creating generated report document:', err);
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ERROR CREATING GENERATED REPORT!');
      return undefined;
    }
  }

  async deleteGeneratedReport(reportId: string) {
    try {
      if (!this.userService.userData?.uid) {
        throw new Error('User ID is undefined');
      }
      const reportRef = doc(
        this.dbModular,
        'generatedReport',
        this.userService.userData?.uid
      );

      const reportDoc = await getDoc(reportRef);
      if (!reportDoc.exists()) {
        throw new Error('Report document does not exist');
      }

      const generatedReports =
        (reportDoc.data() as UserReports)?.generatedReports || [];

      // Find the report to be removed
      const reportToRemove = generatedReports.find(
        (report: any) => report.id === reportId
      );
      if (reportToRemove && reportToRemove.reportUrl !== '') {
        if (
          !reportToRemove ||
          !reportToRemove.id ||
          !reportToRemove.reportUrl
        ) {
          throw new Error('Report not found in generatedReports');
        }
        let file: FileData = {
          id: reportToRemove.id,
          name: reportToRemove.id,
          url: reportToRemove.reportUrl,
        };

        // Check if the report has an associated file to delete
        if (file) {
          // Assuming reportToRemove.file contains data matching the FileData interface
          await this.deleteReportCSV(file);
        }
      }

      // Remove the report from the array
      await updateDoc(reportRef, {
        generatedReports: arrayRemove(reportToRemove),
      });

      this.snackBarService.openBlueSnackBar('Report successfully deleted!');
      return true;
    } catch (err) {
      console.error('Error deleting generated report:', err);
      if (err instanceof Error) this.snackBarService.latestError = err.message;
      this.snackBarService.openRedSnackBar('ERROR DELETING GENERATED REPORT!');
      return false;
    }
  }

  async deleteReportCSV(file: FileData | undefined) {
    if (file?.id) {
      const filePath = `reports/${file.id}.csv`;
      const callable = httpsCallable(this.fireFunctions, 'deleteReportCSV');

      try {
        // Call the cloud function to delete the file
        await callable({
          filePath: filePath,
        });

        this.snackBarService.openBlueSnackBar('FILE DELETED SUCCESSFULLY');
      } catch (err) {
        if (err instanceof Error) {
          this.snackBarService.latestError = err.message;
        }
        this.snackBarService.openRedSnackBar('ERROR DELETING FILE');
        throw err;
      }
    } else {
      console.warn('No file data provided for deletion.');
    }
  }

  extractReport(generatedReportData: ReportConfig) {
    // Call the Cloud Function
    this.http
      .post(
        `${environment.cloudRunServiceUrl}/api/transaction-report/generate-transaction-report`,
        generatedReportData
      )
      .pipe()
      .subscribe({
        next: (response) => {
          console.log('Report generated successfully:', response);
        },
        error: (error) => {
          console.error('Error generating report:', error);
        },
      });
  }

  async cancelReportGeneration(reportId: string) {
    if (!this.userService.userData?.uid) {
      console.error('User ID is undefined');
      return;
    }

    try {
      const reportRef = doc(
        this.dbModular,
        'generatedReport',
        this.userService.userData.uid
      );

      // Fetch the document first
      const reportDoc = await getDoc(reportRef);

      if (!reportDoc.exists()) {
        console.error('Report document does not exist');
        return;
      }

      // Get the generatedReports array from the document
      const userReports = reportDoc.data() as UserReports;
      const generatedReports = userReports.generatedReports;

      // Find the correct report to cancel
      const reportIndex = generatedReports.findIndex(
        (report) => report.id === reportId
      );
      if (reportIndex === -1) {
        console.error('Report not found');
        return;
      }

      // Update the `isCancelled` field of the specific report
      generatedReports[reportIndex].isCancelled = true;
      generatedReports[reportIndex].status = 'CANCELLED';

      // Update the document in Firestore
      await updateDoc(reportRef, { generatedReports });

      console.log('Cancellation request sent successfully.');
    } catch (error) {
      console.error('Error sending cancellation request:', error);
    }
  }

  downloadGeneratedReport(generatedReport: GeneratedReport) {
    if (
      generatedReport?.id &&
      generatedReport?.reportName &&
      generatedReport?.reportUrl
    ) {
      const baseName = generatedReport.reportName;
      const fileData: FileData = {
        url: generatedReport.reportUrl,
        name: `${baseName}.csv`,
      };
      this.fileService.downloadFile(fileData);
    } else {
      this.snackBarService.openRedSnackBar('NO REPORT CSV URL FOUND');
    }
  }

  async setSelectedGeneratedReports() {
    if (!this.userService.userData?.uid) {
      return Promise.resolve({ id: '', generatedReports: [] });
    }

    const userReportDocRef = doc(
      this.dbModular,
      'generatedReport',
      this.userService.userData?.uid
    );

    return new Promise<void>((resolve, reject) => {
      const unsubscribe = onSnapshot(
        userReportDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data() as UserReports;
            this.selectedGeneratedReports = {
              ...data,
              id: docSnapshot.id,
            };
            this.refreshGeneratedReports();
            this.selectedGeneratedReportsUpdated.emit();
            resolve();
          } else {
            this.mainService.setLoading(false);
            this.selectedGeneratedReports = { id: '', generatedReports: [] };
            this.refreshGeneratedReports();
            this.selectedGeneratedReportsUpdated.emit();
            reject(new Error('GENERATED REPORT DOCUMENT NOT FOUND!'));
          }
        },
        (error) => {
          this.snackBarService.latestError = error.message;
          this.snackBarService.openRedSnackBar(
            'ERROR FETCHING GENERATED REPORTS'
          );
          reject(error);
        }
      );

      this.destroy$.subscribe(() => unsubscribe());
    });
  }

  private doesGeneratedReportMatchFilter(
    item: any,
    filterValue: string
  ): boolean {
    const typeMatches =
      this.generatedReportFilter === ReportType.ALL ||
      item.reportType.includes(this.generatedReportFilter);

    const reportName = item.event ? item.event.toUpperCase() : '';
    const reportType = item.event ? item.event.toUpperCase() : '';
    const reportFormat = item.event ? item.event.toUpperCase() : '';
    const status = item.event ? item.event.toUpperCase() : '';
    const createdBy = item.createdBy?.displayName
      ? item.createdBy.displayName.toUpperCase()
      : '';

    const upperCaseFilterValue = filterValue.toUpperCase();

    const textMatches =
      reportName.includes(upperCaseFilterValue) ||
      reportType.includes(upperCaseFilterValue) ||
      reportFormat.includes(upperCaseFilterValue) ||
      status.includes(upperCaseFilterValue) ||
      createdBy.includes(upperCaseFilterValue);

    return typeMatches && textMatches;
  }

  updateCurrentGeneratedReports(generatedReport: GeneratedReport[]) {
    if (!this.dataSourceGeneratedReports) {
      this.dataSourceGeneratedReports =
        new MatTableDataSource<GeneratedReport>();
    } else {
      this.dataSourceGeneratedReports.data = generatedReport;
      this.dataSourceGeneratedReports._updateChangeSubscription();
    }
  }

  applyFilterGeneratedReports(filterValue?: string): void {
    if (filterValue) {
      this.searchTextGeneratedReports = filterValue.trim().toUpperCase();
    }

    this.filteredGeneratedReports =
      this.allGeneratedReports?.filter((item) =>
        this.doesGeneratedReportMatchFilter(
          item,
          this.searchTextGeneratedReports
        )
      ) ?? [];
  }

  public refreshGeneratedReports() {
    if (this.selectedGeneratedReports?.generatedReports) {
      const sortedGeneratedReports =
        this.selectedGeneratedReports.generatedReports.slice().sort((a, b) => {
          const aCreatedOn = a.createdOn ? a.createdOn.toDate().getTime() : 0;
          const bCreatedOn = b.createdOn ? b.createdOn.toDate().getTime() : 0;
          return bCreatedOn - aCreatedOn;
        });
      this.allGeneratedReports = sortedGeneratedReports;
      this.applyFilterGeneratedReports();
    }
  }

  updateCurrentReportConfigs(configs: ReportConfig[]) {
    if (!this.dataSourceReportConfigs) {
      this.dataSourceReportConfigs = new MatTableDataSource<ReportConfig>(
        configs
      );
    } else {
      this.dataSourceReportConfigs.data = configs;
      this.dataSourceReportConfigs._updateChangeSubscription();
    }
  }

  applyFilter(filterValue?: string): void {
    if (filterValue) {
      this.searchTextReportConfigs = filterValue.trim().toUpperCase();
    }

    this.filteredReportConfigs =
      this.allReportConfigs?.filter((item) =>
        this.doesConfigMatchFilter(item, this.searchTextReportConfigs)
      ) ?? [];
  }

  private doesConfigMatchFilter(
    item: ReportConfig,
    filterValue: string
  ): boolean {
    const typeMatches =
      this.reportConfigFilter === ReportType.ALL ||
      item.reportType === this.reportConfigFilter ||
      false;

    const ownershipMatches =
      !this.filterService.ownedReportsFilter ||
      (item.createdBy?.uid !== undefined &&
        item.createdBy.uid === this.userService.userData?.uid);

    const format = item.reportFormat ? item.reportFormat.toUpperCase() : '';
    const name = item.reportName ? item.reportName.toUpperCase() : '';
    const description = item.description ? item.description.toUpperCase() : '';
    const createdBy = item.createdBy?.displayName
      ? item.createdBy.displayName.toUpperCase()
      : '';

    const upperCaseFilterValue = filterValue.toUpperCase();

    const textMatches =
      format.includes(upperCaseFilterValue) ||
      name.includes(upperCaseFilterValue) ||
      description.includes(upperCaseFilterValue) ||
      createdBy.includes(upperCaseFilterValue);

    return typeMatches && textMatches && ownershipMatches;
  }

  doesOwnReport(reportConfig?: ReportConfig): boolean {
    return reportConfig
      ? reportConfig.createdBy?.uid === this.userService.userData?.uid
      : false;
  }

  resetSelectedReport() {
    this.selectedReport = undefined;
  }

  cleanUp() {
    this.resetSelectedReport();
    this.destroy$.next();
  }
}
