export const environment = {
  production: false,
  url: 'https://dev.ionbase.co.za/',
  title: 'IonBase Dev 🛠️',
  homeTitle: '🛠️ DEVELOP',
  name: 'Dev',
  sentryDSN:
    'https://4395cd81cdbc4ad0aee1479ccf7a019b@o4505509938593792.ingest.sentry.io/4505509999869952',
  meiliPolicyIndex: 'ionDev-policy',
  meiliUsersIndex: 'ionDev-users',
  meiliTransactionIndex: 'ionDev-transaction',
  meiliEndpoint: 'https://meili-inp3.onrender.com',
  meiliAPIKey: '/ppDcWW05SLEg1SQHaBrMUWJCZYESoYpTmKSCiFFAN4=',
  smsPortalKey: '69129931-2a7f-43dc-9471-e65f1a11fd07',
  smsPortalSecret: 'f0e5b928-80b8-4622-a02f-221123a49038',
  cloudRunServiceUrl:
    'https://cloud-run-service-1000028336722.europe-west3.run.app',
  // cloudRunServiceUrl: 'http://localhost:8080',
  payAtPrefix: '11701',
  generatedPolicyNumberPrefix: 'WAN',
  firebase: {
    apiKey: 'AIzaSyBXhh87y2kWVqMZ91wSQOD0AgyHAoTh0bI',
    authDomain: 'ionbasedev.firebaseapp.com',
    projectId: 'ionbasedev',
    storageBucket: 'ionbasedev.appspot.com',
    messagingSenderId: '1000028336722',
    appId: '1:1000028336722:web:97b65b541f355fbab1e58b',
    measurementId: 'G-WH6JNMW4EY',
  },
};
